import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import JupiterLogo from "../images/JupiterHoldingsLogo_Revised_CMYK.png"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    const { showMenu } = this.state;
    const burgerClass = (showMenu) ? "navbar-burger burger is-active" : "navbar-burger burger";
    const menuClass = (showMenu) ? "navbar-menu is-active" : "navbar-menu";

    return (
      <header>
        <nav className="navbar is-spaced is-transparent" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/"><img src={JupiterLogo} width="160"/></Link>
            <a onClick={this.toggleMenu} role="button" className={burgerClass} aria-label="menu" aria-expanded="false" data-target="navbarMenu">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div id="navbarMenu" className={menuClass}>
            <div className="navbar-start">
              <Link to="/real-estate" className="navbar-item">Real Estate</Link>
              <Link to="/private-equity" className="navbar-item">Private Equity</Link>
              <Link to="/marketable-securities" className="navbar-item">Marketable Securities</Link>
            </div>
            <div className="navbar-end">
              <Link to="/contact" className="navbar-item">Contact Us</Link>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
