import { Link } from "gatsby"
import React from "react"

const Footer = () => (
    <footer className="footer has-background-white">
        <div className="content has-text-centered">
            <p>
               &copy; {new Date().getFullYear()} Jupiter Holdings LLC | <Link to="/user-agreement">User Agreement</Link> | <Link to="/privacy">Privacy Policy</Link> 
            </p>
        </div>
    </footer>
);

export default Footer;